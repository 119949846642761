<template>
	<div class="home">
		<div class="left" v-loading="loading1">
			<div class="tree">
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]"
					:default-checked-keys="[1]" :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<!-- <img :src="data.path" style="margin-right: 10px;" /> -->

						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.grid_name}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div>
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="网格名"><el-input v-model="searchName"></el-input></el-form-item>
						<el-form-item label="小区名"><el-input v-model="xqName"></el-input></el-form-item>
						<el-form-item label="楼栋号"><el-input v-model="ldNum"></el-input></el-form-item>

						<el-form-item><el-button type="primary" @click="chaxun"><i class="el-icon-search"></i>
								查询</el-button></el-form-item>
						<el-button type="primary" @click="xinzeng()" v-if="newbutton"><i class="el-icon-plus"></i>
							新增</el-button>
					</el-form>
				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column prop="grididname" label="网格名" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="regionname" label="小区名" width="250" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="build_id" label="楼栋号" width="250" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column label="操作" width="200" height show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<!-- <el-button @click="xiugai(scope.row.id)" type="primary">修改</el-button> -->
								<el-button @click="shanchu(scope.row.id)" type="primary">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
							:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
							layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>
				<el-dialog custom-class="tanchuang" title="新增网格楼栋" width='30%' :visible.sync="dialogTableVisible"
					:before-close="handleClose" :close-on-click-modal="false">
					<div class="loudong">
						<div class="clearfix">
							<p style="float: left;line-height: 32px;">选择小区：</p>
							<el-select style="float: left;" v-model="xiaoqu" placeholder="请选择小区">
								<el-option v-for="item in options" :key="item.regioncode" :label="item.regionname"
									:value="item.regioncode">
								</el-option>
							</el-select>
						</div>
						<div v-show="xiaoqu" style="text-align: left;">
							<p style="margin-bottom: 4px;">选择楼栋：</p>
							<el-checkbox-group v-model="loudong">
								<el-checkbox style="margin: 6px 30px 6px 0;" v-for="item in xuanxiang" :key="item.build_id"
									:label="item.build_id" :disabled="item.gridid != null">{{item.buildname}}</el-checkbox>
							</el-checkbox-group>
						</div>
						<div>
							<el-button type="primary" @click="onSubmit">立即创建</el-button>
							<el-button @click="close">取消</el-button>
						</div>
					</div>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getwanggelist,
		getbuildlist,
		addGridBuild,
		updateGridBuild,
		gridbuildlist,
		xiaoqulist
	} from '@/api/wangge'
	export default {
		data() {
			return {
				time: new Date(),
				treeId: "",
				searchName: '',
				xqName: '',
				ldNum: '',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10, 20, 30, 40],
					pagesize: 10,
					totalnum: 0
				},
				xiaoqu: '',
				options: [],
				xuanxiang: [],
				loudong: [],

				max: '',
				dan: '',
				defaultProps: {
					children: 'wanggelist',
					label: 'grid_name'
				},
				treeSelectId: "",
				tree: [],
				newbutton: false,
				regioncode: '',
				levelcode: '',
				gridid: '',
				loading1: true,
				loading2: true
			}
		},
		watch: {
			xiaoqu(val, oldVal) {
				var that = this;
				if (val != '') {
					getbuildlist({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						regionlevel: that.levelcode,
						regioncode: val,
						buildid: '',
						page: '',
						pagesize: '',
						searchname: '',
						type: 1
					}).then((res) => {
						if (res.result == '200') {
							that.xuanxiang = res.detail;
						} else {
							that.$message({
								showClose: true,
								type: 'error',
								message: res.description
							});
						}
					})
				}
			}
		},
		mounted() {
			this.getList();
			this.getTree();
		},
		methods: {
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			getTree() {
				this.loading1 = true;
				getwanggelist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
						// console.log(this.tree);
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].grid_name = this.tree[a].name;
							this.tree[a].ji = 1;
							this.tree[a].num = a;
							for (var i = 0; i < this.tree[a].wanggelist.length; i++) {
								this.tree[a].wanggelist[i].name = this.tree[a].name.split('(')[0];
								this.tree[a].wanggelist[i].ji = 2;
								this.tree[a].wanggelist[i].num = i;
								this.tree[a].wanggelist[i].prevnum = a;
							}
						}
					
						// this.tableData = this.tree[0].wanggelist;
						// this.$nextTick(() => {
						// 	this.$refs.tree.setCurrentKey(3);
						// })
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				done();
			},

			onSubmit() {
				var that = this;

				if (that.loudong.length == 0) {
					that.$message.error('请至少选择一个楼栋');
					return;
				}
				let list = that.loudong;
				// for (var i = 0; i < list.length; i++) {
				// 	list[i] = list[i].split('栋')[0];
				// }
				addGridBuild({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					gridid: that.gridid,
					regionlevel: that.levelcode,
					regioncode: that.xiaoqu,
					buildid: list.join(',')
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '添加成功',
							type: 'success'
						});
					
						that.searchName = '';
						that.xqName = '';
						that.ldNum = '';
						that.getList();
						that.dialogTableVisible = false;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			close() {
				var that = this;
				that.dialogTableVisible = false;
			},
			shanchu(a) {
				var that = this;
				that.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					updateGridBuild({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						id: a
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.currentPage = 1;
							that.searchName = '';
							that.xqName = '';
							that.ldNum = '';
							that.getList();
						} else {
							that.$message({
								showClose: true,
								type: 'error',
								message: res.description
							});
						}
					})

				}).catch(() => {

				});
			},
			chaxun() {
				this.currentPage = 1;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				gridbuildlist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					gridid: that.gridid,
					page: that.currentPage,
					pagesize: that.fenye.pagesize,
					seachname: that.searchName,
					regionname: that.xqName,
					buildnum: that.ldNum
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			nodeClick(data) {
				// console.log(data);
				var that = this;
				that.treeClick(data);

			},
			treeClick(a) {
				var that = this;
				that.searchName = '';
				that.ldNum = '';
				that.xqName = '';
				that.levelcode = '';
				that.gridid = '';
				that.currentPage = 1;
				if (a.ji == 2) {
					that.newbutton = true;
					that.gridid = a.id;
					that.levelcode = a.grid_level;


				} else {
					that.newbutton = false;
					that.levelcode = a.levelcode;
				}
				that.getList();
			},
			xinzeng() {
				var that = this;
				that.dialogTableVisible = true;
				that.xiaoqu = '';

				that.loudong = [];
				that.options = [];
				var li;
				xiaoqulist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					regionlevel: that.levelcode,
					regioncode: '',
					page: '',
					pagesize: '',
					searchname: '',
					type: 1
				}).then((res) => {
					if (res.result == '200') {
						that.options = res.detail;
						// console.log(that.options);
					} else {
						that.$message({
							showClose: true,
							type: 'error',
							message: res.description
						});
					}
				})
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">

	.clearfix::before,
	.clearfix::after {
		content: "";
		height: 0;
		line-height: 0;
		display: block;
		visibility: hidden;
		clear: both;
	}

	.clearfix:after {
		clear: both;
	}

	.clearfix {
		*zoom: 1;
		/*IE/7/6*/
	}

	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.loudong>p,
	.loudong>div {
		padding: 10px 0;
	}
</style>